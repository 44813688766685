export default {
  getCampaigns(state) {
    return state.campaigns;
  },
  getCurrentCampaign(state) {
    return state.currentCampaign;
  },
  getSearchResults(state) {
    return state.search;
  },
};
