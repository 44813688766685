import Vue from "vue";
import VueRouter from "vue-router";

import AuthRequired from "./guards/auth_required";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/auth/LoginClient.vue"),
    meta: {
      resource: "login",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () =>
      import(
        /* webpackChunkName: "register" */ "@/views/auth/RegisterClient.vue"
      ),
    meta: {
      resource: "register",
    },
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "notFound" */ "@/views/NotFound.vue"),
    meta: {
      resource: "not-found",
    },
  },
  {
    path: "/",
    redirect: "/campaigns",
    component: () => import(/* webpackChunkName: "main" */ "@/views"),
    children: [
      {
        path: "/campaigns",
        name: "Campaigns",
        beforeEnter: AuthRequired,
        component: () =>
          import(
            /* webpackChunkName: "campaigns" */ "@/views/campaign/TheCampaign.vue"
          ),
        meta: {
          resource: "campaigns",
        },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
