import { handleHttpError, reportSuccess, baseUrl, getToken } from "./utils";
const axios = require("axios").default;

async function fetchCampaigns() {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/campaigns`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    return response.data.data;
  } catch (err) {
    const error =
      `Error fetching Campaigns: ${err.response.data.message}` ||
      "An error occurred fetching Campaigns. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function fetchCampaign(id) {
  const token = await getToken();
  try {
    const response = await axios.get(`${baseUrl}/campaigns/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data.campaign;
  } catch (err) {
    const error =
      `Error fetching Campaign: ${err.response.data.message}` ||
      "An error occurred fetching Campaign. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

async function addCampaign(payload) {
  const token = await getToken();
  let response;
  const { mode, data } = payload;
  try {
    if (mode === "schedule") {
      response = await axios.post(`${baseUrl}/campaigns/schedule`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    } else {
      response = await axios.post(`${baseUrl}/campaigns/`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });
    }

    if (response.data.data.message) {
      const message = `Added campaign successfully`;
      reportSuccess(message);
      return true;
    }
  } catch (err) {
    const errMessage =
      err.response.data?.data?.error?.message || err.response.data?.error;
    const error =
      `Error Adding Campaign: ${errMessage}` ||
      "An error occurred adding Campaign. Please try again or contact the System Admin";
    handleHttpError(error);
    return false;
  }
}

async function deleteCampaign(id) {
  try {
    const token = await getToken();

    await axios.delete(`${baseUrl}/campaigns/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
    });

    const message = `Campaign deleted successfully`;
    return reportSuccess(message);
  } catch (err) {
    const error =
      `Error Deleting Campaign: ${err.response.data.error}` ||
      "An error occurred Deleting Campaign.Please try again or Contact the System Admin ";
    return handleHttpError(error);
  }
}

async function searchCampaigns(payload) {
  try {
    const token = await getToken();
    const response = await axios.post(`${baseUrl}/campaigns/search`, payload, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return response.data.data;
  } catch (err) {
    const error =
      `Error searching campaigns: ${err.response.data.error}` ||
      "An error occurred searching campaigns. Please try again or contact the System Admin";
    return handleHttpError(error);
  }
}

export {
  fetchCampaigns,
  fetchCampaign,
  addCampaign,
  deleteCampaign,
  searchCampaigns,
};
