import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#FFA000",
        secondary: "#BCAAA4",
        accent: "#b39184",
        error: "#E53935",
        info: "#131c26",
        success: "#2E7D32",
        warning: "#ee7b10",
        background: "#E5E5E5",
        iconFill: "#84818A",
        expansionPanels: "#EAEEF9",
        textGray: "#84818a",
      },
      dark: {
        primary: "#081623",
        secondary: "#000000",
        accent: "#c97e5b",
      },
    },
  },
});
