import {
  fetchCampaigns,
  fetchCampaign,
  addCampaign,
  deleteCampaign,
  searchCampaigns,
} from "@/helpers/campaign.js";

export default {
  async fetchCampaigns({ commit }, payload) {
    const campaigns = await fetchCampaigns(payload);
    return commit("setCampaigns", campaigns);
  },

  async fetchCampaign(_, id) {
    return await fetchCampaign(id);
  },

  async addCampaign(_, payLoad) {
    return await addCampaign(payLoad);
  },

  async deleteCampaign(_, id) {
    await deleteCampaign(id);
  },
  async searchCampaigns({ commit }, payLoad) {
    const results = await searchCampaigns(payLoad);
    return commit("setSearchResults", results);
  },
};
