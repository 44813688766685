export default {
  setCampaigns(state, payLoad) {
    return (state.campaigns = payLoad);
  },
  setCurrentCampaign(state, payLoad) {
    return (state.currentCampaign = payLoad);
  },
  setSearchResults(state, payLoad) {
    return (state.search = payLoad);
  },
};
